.viewNavigation {
  margin-bottom: 20px;
}

.loading-indicator {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: -36px;
  margin-top: -36px;
}
