.RenderOverlay {
  width: 100%;
  height: 100%;
  position: relative;
}

.RenderOverlay img,
.RenderOverlay canvas {
  position: absolute;
  display: block;
  width: auto;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  visibility: hidden;
}

.RenderOverlay canvas.resultCanvas {
  position: relative;
  visibility: visible;
  background: #f5f5f5;
}
