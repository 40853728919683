.InfoBox {
  padding: 16px 16px;
  font-size: 12px;
  border: 1px solid #00000022;
}

.InfoBox--information {
  background-color: #d7f1ff;
}

.InfoBox--warning {
  background-color: #fffbd6;
}

.InfoBox--error {
  background-color: #ffcccc;
}

.InfoBox-header {
  font-weight: 700;
  margin-bottom: 6px;
}
